.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom, var(--background-gray-color) 50%, rgb(25, 24, 24) 50%);
}

.footer-container-support {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
}

.footer-container-top {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
  margin-bottom: 1%;
}

.footer-container-bot {
  text-align: center;
  line-height: 1.5;
  font-family: 'Eastman Trial', sans-serif;
  color: rgb(203, 202, 201);
  font-size: 25px;
  margin-top: 2%;
  
  margin-bottom: 1%;
}

#footer-a {
  text-decoration: none;
  color: rgb(203, 202, 201);
  cursor: pointer;
}

@media (max-width: 1024px) {
  .footer-container-bot {
    font-size: 19px;
  }
}

@media (max-width: 767px) {
  .footer-container-support {
    width: 80%;
  }

  .footer-container-top {
    padding-bottom: 20px;
  }

  .footer-container-bot {
    font-size: 12px;
  }
}