.about-container {
  display: flex;
  height: auto;
  background-color: var(--background-gray-color);
  align-items: center;
  justify-content: center;
  padding: 5em 0px;
}

.about-text {
  opacity: 0;
  transform: translateX(-20px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.about-right {
  opacity: 0;
  transform: translateX(20px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;

}

.about-text.show,
.about-right.show {
  opacity: 1;
  transform: translateX(0);
}

.about-information {
  display: flex;
  flex-direction: column;
  gap: 3em;
  justify-content: flex-start;
  width: 60%;
}

.about-support {
  display: flex;
  gap: 3em;
  justify-content: space-between;
}

.about-text {
  text-align: justify;
  font-family: 'Eastman Trial', sans-serif;
  color: var(--text-color);
  font-size: 16px;
  line-height: 1.5;
  font-size: 18px;
}

.about-header {
  flex-basis: 100%;
}

#about-text-image {
  width: 212px;
}

.about-right {
  display: flex;
  flex-direction: column;
}

#about-logo-image {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#about-title {
  display: block;
  width: 250px;
  border-top: 1px solid #d2cbb8;
  border-bottom: 1px solid #d2cbb8;
  border-left: 7px solid #d2cbb8;
  border-right: 7px solid #d2cbb8;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
  font-size: 23px;
  letter-spacing: 8px;
  font-family: 'Eastman Trial', sans-serif;
  color: #d2cbb8;
}

#about-image-team {
  width: 450px;
}

@media (min-width: 1440px) and (max-width: 1660px) {
  #about-image-team {
    width: 350px;
  }
}

@media (max-width: 1366px) {
  .about-text {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  #about-title {
    font-size: 20px;
  }
}