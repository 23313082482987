.expertise-container {
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
  background-color: var(--background-gray-color);
  padding: 80px 0px 80px 0px;
}

.expertise-support {
  display: flex;
  flex-direction: column;
  gap: 60px;
  width: 60%;

}

.expertise-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.expertise-title {
  width: 250px;
  border-top: 1px solid #d2cbb8;
  border-bottom: 1px solid #d2cbb8;
  border-left: 7px solid #d2cbb8;
  border-right: 7px solid #d2cbb8;
  padding: 10px;
  text-align: center;
}

#expertise-title-span {
  text-align: center;
  text-transform: uppercase;
  font-size: 25px;
  letter-spacing: 8px;
  font-family: 'Eastman Trial', sans-serif;
  color: #d2cbb8;
}

.expertise-buttons {
  display: flex;
  gap: 15px;
}

.expertise-button-selector {
  font-family: 'Eastman Trial', sans-serif;
  color: #d2cbb8;
  padding: 10px;
  border-radius: 50%;
  border: 1px solid rgb(110, 107, 99);
  font-weight: 800;
}

.expertise-button-text {
  font-family: 'Eastman Trial', sans-serif;
  color: #c7c6c3;
}

.expertise-next,
.expertise-previous-button {
  display: flex;
  align-items: center;
  gap: 10px;
}

.expertise-buttons-separator {
  flex: 1;
  width: 1px;
  background-color: #d2cbb8;
}


.expertise-cards-container {
  display: flex;
  -webkit-box-shadow: 0px 0px 50px 22px rgba(0, 0, 0, 0.48);
  -moz-box-shadow: 0px 0px 50px 22px rgba(0, 0, 0, 0.48);
  box-shadow: 0px 0px 50px 22px rgba(0, 0, 0, 0.48);

}

.expertise-cards-container .expertise-card:first-child {
  box-shadow: inset -18px -2px 20px rgba(0, 0, 0, 0.5);
}

.expertise-cards-container .expertise-card:last-child {
  box-shadow: inset 20px 0px 20px 0px rgba(0, 0, 0, 0.5);
}

.expertise-card {
  width: auto;
  height: 417px;
  border: 1px solid rgb(107, 107, 107);
  background-color: rgb(65, 65, 65);
  display: flex;
  align-items: center;
  justify-content: center;
}

.expertise-card-support {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 70%;
}

.expertise-card-image {
  margin-bottom: 20px;
  height: 80px
}

.expertise-card-title {
  font-family: 'Eastman Trial', sans-serif;
  color: #d2cbb8;
  font-weight: 600;
  font-size: 25px;
  margin-bottom: 15px;
  line-height: 1.2;
}

.expertise-card-content {
  font-family: 'Eastman Trial', sans-serif;
  color: #a1a09f;
  font-size: 20px;
  line-height: 1.3;
}

.expertise-line {
  height: 1px;
  background-color: #d2cbb8;
}

@media (max-width: 1440px) {
  #inovationTitle .expertise-card-title {
    font-size: 20px;
    line-height: 1.3;
  }
  #sustTitle .expertise-card-title {
    font-size: 20px;
    line-height: 1.3;
  }
}

@media (max-width: 1366px) {
  #inovationTitle .expertise-card-title {
    font-size: 20px;
    line-height: 1.3;
  }

  #sustTitle .expertise-card-title {
    font-size: 20px;
    line-height: 1.3;
  }

}

@media (max-width: 1024px) {
  #expertise-title-span {
    font-size: 18px;
  }

  .expertise-title {
    width: 200px;
  }

  .expertise-card-image {
    height: 80px;
  }

  .expertise-card-title {
    font-size: 15px;
    line-height: 1.3;
  }

  .expertise-card-content {
    font-size: 13px;
  }

  .expertise-card {
    height: 340px;
  }

  #inovationTitle .expertise-card-title {
    font-size: 16px;
    line-height: 1.3;
  }

  #sustTitle .expertise-card-title {
    font-size: 14px;
    line-height: 1.3;
  }
}

@media (max-width: 767px) {
  .expertise-support {
    width: 80%;
  }

  .expertise-card-support {
    width: 80%;
  }

  .expertise-header {
    flex-direction: column;
    gap: 15px;
  }

  .expertise-card-image {
    height: 70px;
  }

  .expertise-card-title {
    font-size: 20px;
  }

  .expertise-card-content {
    font-size: 18px;
  }

  .expertise-card {
    height: 220px;
  }

  #inovationTitle .expertise-card-title {
    font-size: 20px;
    line-height: 1.3;
  }

  #inovationTitle .expertise-card-image {
    height: 60px;
  }
}

@media (max-width: 768px) {
  .expertise-card {
    height: 310px;
  }
}

* {
  --swiper-theme-color: #d2cbb8;
}

.arrow-image {
  filter: invert(99%) sepia(2%) saturate(3041%) hue-rotate(325deg) brightness(88%) contrast(84%);
  width: 25px;
}

#left-arrow {
  transform: rotate(180deg);
}