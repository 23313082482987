@font-face {
  font-family: "partners";
  src: url("partnersFont.otf");
}

.second-banner-div-business {
  position: absolute;
  display: none;
  flex-direction: column;
  gap: 5px;
  bottom: 5%;
  right: 10%;
}

.second-banner-business-text {
  color: white;
  cursor: default;
  font-family: "Eastman Trial", sans-serif;
}

.main-logo {
  width: 220px;
  height: 44px;
}

.main-banner {
  width: 100%;
  height: 100%;
}

.first-banner-support {
  display: flex;
  align-items: center;
}

.second-banner-principal {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 20px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.second-banner-principal-span {
  color: white;
  font-family: "Eastman Trial", sans-serif;
  font-size: 35px;
  cursor: default;
  margin-top: 30px;
}

.second-banner-principal-span-down {
  color: white;
  font-family: "Eastman Trial", sans-serif;
  font-size: 15px;
  cursor: default;
  font-weight: 300;
}

.third-banner-principal {
  position: absolute;
  display: flex;
  left: 12%;
  flex-direction: column;
  align-items: flex-end;
  top: 20%;
}

.third-banner-principal-span {
  color: white;
  font-family: "Eastman Trial", sans-serif;
  font-size: 35px;
  cursor: default;
}

.fourth-banner-principal {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 35px;
  left: 25%;
  top: 40%;
}

.fourth-banner-principal-span {
  color: white;
  font-family: "Eastman Trial", sans-serif;
  font-size: 35px;
  cursor: default;
  line-height: 1;
  margin-top: 30px;
}

.fourth-banner-support-span {
  color: white;
  font-family: "Eastman Trial", sans-serif;
  background-color: #003f25;
  padding: 15px;
  letter-spacing: 7px;
}

.div-business {
  display: flex;
  flex-direction: column;
  color: white;
  font-family: "Eastman Trial", sans-serif;
}

.first-banner-principal {
  position: absolute;
  display: flex;
  left: 50%;
  /* gap: 5px; */
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  justify-content: center;
}

.first-banner-image {
  width: 240px;
}

.partners-span {
  color: white;
  font-family: "partners";
  font-size: 110px;
}

.first-banner-span {
  color: #b3b3b3;
  font-family: "Eastman Trial", sans-serif;
  font-size: 30px;
  white-space: pre-line;
  letter-spacing: 10px;
}

.fifth-banner-principal {
  position: absolute;
  display: flex;
  gap: 20px;
  left: 10%;
  top: 15%;
  align-items: center;
}

.fifth-banner-image {
  width: 400px;
}

.fifth-banner-principal-span {
  line-height: 1.2;
  cursor: default;
}

#ecoair_banner_text_support {
  display: flex;
  flex-direction: column;
  gap: 10px;
  line-height: 1;
}

#ecoairBannerText {
  color: white;
  font-family: "Eastman Trial", sans-serif;
  white-space: pre-line;
  font-size: 25px;
  text-align: center;
  font-weight: 500;
  background-color: #05a593;
  padding: 10px;
  border-radius: 10px;
}

#ecoairBannerText1 {
  color: white;
  font-family: "Eastman Trial", sans-serif;
  white-space: pre-line;
  font-size: 27px;
  text-align: left;
}

#ecoairBannerText2 {
  color: #04a593;
  font-weight: 500;
  font-family: "Eastman Trial", sans-serif;
  font-size: 18px;
}

#ecoairBannerText3 {
  color: #04a593;
  font-weight: 600;
  font-family: "Eastman Trial", sans-serif;
  font-size: 35px;
  letter-spacing: 5px;
}

@media (width: 1550px) {
  .fifth-banner-principal {
    left: 35%;
  }
}

@media (max-width: 1440px) {
  .second-banner-principal-span {
    color: white;
    font-family: "Eastman Trial", sans-serif;
    font-size: 20px;
  }

  .fourth-banner-support-span {
    letter-spacing: 4px;
  }

  .third-banner-principal-span {
    font-size: 30px;
  }

  .third-banner-image {
    width: 350px;
  }
  .fourth-banner-principal {
    gap: 20px;
  }
}

@media (max-width: 1366px) {
}

@media (max-width: 1280px) {
  .third-banner-principal-span {
    font-size: 23px;
  }

  .third-banner-image {
    width: 300px;
  }

  /* .first-banner-principal {
    top: 20%;
  } */

  .fifth-banner-image {
    width: 350px;
  }

  #ecoairBannerText {
    font-size: 22px;
  }

  #ecoairBannerText1 {
    font-size: 24px;
  }

  #ecoairBannerText2 {
    font-size: 16px;
  }

  #ecoairBannerText3 {
    font-size: 30px;
  }
}

@media (max-width: 1024px) {
  .partners-span {
    font-size: 80px;
  }

  .fifth-banner-image {
    width: 200px;
  }

  .first-banner-image {
    width: 140px;
  }

  .first-banner-span {
    font-size: 15px;
  }

  /* .first-banner-span {
    font-size: 30px;
  } */

  .security-logo {
    width: 400px;
  }

  .second-banner-principal-span {
    font-size: 16px;
  }

  .third-banner-principal {
    top: 30%;
  }

  .third-banner-image {
    width: 220px;
  }

  .fourth-banner-principal-span {
    font-size: 30px;
    line-height: 1.2;
    white-space: pre-line;
  }

  #ecoairBannerText {
    font-size: 19px;
  }

  #ecoairBannerText2 {
    font-size: 14px;
  }

  #ecoairBannerText3 {
    font-size: 26px;
  }

  .fourth-banner-principal {
    gap: 10px;
  }

  .fourth-banner-support-span {
    font-size: 14px;
  }
}

@media (max-width: 950px) {
  .security-logo {
    width: 320px;
  }

  .second-banner-principal-span {
    font-size: 14px;
  }

  .third-banner-image {
    width: 170px;
  }

  .third-banner-principal-span {
    font-size: 20px;
    line-height: 1.1;
  }

  .third-banner-principal {
    left: 10%;
  }

  .fourth-banner-principal-span {
    font-size: 30px;
  }
}

@media (max-width: 768px) {
  .first-banner-image {
    width: 100px;
  }

  .first-banner-span {
    font-size: 12px;
  }

  .fifth-banner-image {
    /* width: 250px; */
    height: 70px;
  }

  #ecoairBannerText {
    font-size: 16px;
  }

  #ecoairBannerText2 {
    font-size: 14px;
    letter-spacing: 0px;
  }

  #ecoairBannerText3 {
    font-size: 26px;
  }

  .fourth-banner-support-span {
    display: none;
  }

  .div-business {
    display: none;
  }

  .fourth-banner-principal-span {
    font-size: 26px;
    margin-top: 22px;
  }

  .fifth-banner-principal-span {
    display: none;
  }
}

@media (max-width: 640px) {
  .partners-span {
    font-size: 60px;
  }

  .first-banner-image {
    display: none;
  }

  .first-banner-support {
    width: 80%;
  }

  /* .first-banner-span {
    font-size: 15px;
  } */

  /* .first-banner-span {
    font-size: 20px;
  } */

  .second-banner-principal-span-down {
    display: none;
  }

  .second-banner-principal {
    top: 25%;
  }

  .security-logo {
    width: 210px;
  }

  /* .second-banner-principal-span {
    font-size: 12px;
  } */

  .second-banner-principal-span {
    margin-top: 130px;
  }

  .main-logo {
    width: 150px;
    height: 30px;
  }

  .third-banner-principal-span {
    font-size: 15px;
  }

  .fourth-banner-principal {
    gap: 10px;
  }

  .fourth-banner-principal-span {
    font-size: 25px;
  }

  .fourth-banner-principal {
    top: 24%;
  }

  .fifth-banner-principal-span {
    display: none;
  }

  #ecoair_banner_text_support {
    display: none;
  }

  .fifth-banner-image {
    width: 220px;
    height: auto;
  }
  .fifth-banner-principal {
    left: 50%;
    top: 22%;
    transform: translate(-50%, -50%);
  }
}

@media (width: 600px) {
  .fourth-banner-principal-span {
    margin-top: 40px;
  }
}

@media (max-width: 430px) {
  .partners-span {
    font-size: 40px;
  }
  .second-banner-principal-span {
    margin-left: 20px;
  }

  /* .first-banner-span {
    font-size: 15px;
  } */

  .security-logo {
    width: 160px;
  }

  .main-logo {
    width: 100px;
    height: 20px;
  }

  .second-banner-business-text {
    font-size: 12px;
  }

  .third-banner-image {
    width: 90px;
  }

  .third-banner-principal-span {
    font-size: 10px;
    width: 40%;
  }

  .third-banner-principal {
    left: 15%;
  }

  .fourth-banner-principal-span {
    font-size: 15px;
  }

  .fourth-banner-principal {
    top: 30%;
  }

  .fifth-banner-image {
    width: 150px;
  }
}

@media (max-width: 375px) {
  .third-banner-principal-span {
    font-size: 12px;
    width: 50%;
  }

  .second-banner-principal-span {
    font-size: 12px;
    margin-top: 110px;
  }
}
