.missions-container {
  display: flex;
  height: 740px;
  background: url('../images/mission_bg.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  justify-content: center;
  padding: 40px 0px 40px 0px;
}

.missions-card {
  opacity: 0;
  transform: translateY(20px); 
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.missions-card.show {
  opacity: 1;
  transform: translateY(0); 
}

.missions-support {
  display: flex;
  flex-direction: column;
  gap: 2em;
  width: 60%;
}

.missions-card {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2em;

}

.title-missions-card {
  color: #d2cbb8;
  width: 250px;
  text-align: center;
  padding: 10px 20px 10px 20px;
  border: 1px solid #d2cbb8;
  font-size: 50px;
  letter-spacing: 5px;
  font-family: 'Eastman Trial', sans-serif;
}

.content-missions-card {
  font-family: 'Eastman Trial', sans-serif;
  color: var(--text-color);
  font-size: 25px;
  white-space: pre-line;
}

@media (max-width: 1440px) {

  .title-missions-card {
    min-width: 250px;
  }
  .content-missions-card {
    line-height: 1.3;
  }
  
}

@media (max-width: 1024px) {
  .content-missions-card {
    font-size: 15px;
  }
  .title-missions-card {
    max-width: 200px;
    min-width: 200px;
    font-size: 35px;
  }
  
}

@media (max-width: 767px) {
  .missions-support {
    width: 80%;
  }

  .title-missions-card {
    max-width: 150px;
    min-width: 150px;
    font-size: 20px;
  }

  .content-missions-card {
    font-size: 13px;
    line-height: 1.2;
  }
}