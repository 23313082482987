.work-container {
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #d0c9b7;
  padding: 40px 0 0 0;
}

.work-support {
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

#work-header-title {
  font-family: 'Eastman Trial', sans-serif;
  color: #414141;
  font-size: 30px;
}


.work-form {
  display: flex;
  gap: 110px;
  align-items: flex-end
}

.work-form-content form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
}

.work-form-input {
  background-color: #414141;
  padding: 5px 4px;
  width: 447px;
  height: 41px;
  border: none;
  margin-bottom: 20px;
  padding: 10px 10px;
  color: #b4b7b9;
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

input::placeholder,
textarea::placeholder,
#work-form-curriculum {
  color: #b4b7b9;
  font-family: 'Eastman Trial', sans-serif;
}

#woman-image {
  width: 500px;
}

@media (max-width: 1366px) {
  #woman-image {
    width: 400px;
  }
}

#work-form-message {
  height: 200px;

  color: #b4b7b9;
}

#work-form-button {
  padding: 10px 97px;
  border: none;
  border-radius: 4px;
  color: #d0c9b7;
  background-color: #414141;
}

@media (max-width: 1440px) {
  .work-form {
    gap: 40px;
  }
}

@media (max-width: 1024px) {
  .work-support {
    gap: 20px;
  }

  #work-header-title {
    font-size: 24px;
  }

  .work-form {
    justify-content: center;
  }

  #woman-image {
    display: none;
  }


}



@media (max-width: 767px) {
  .work-form-input {
    width: 350px;
  }
}

@media (max-width: 350px) {
  .work-form-input {
    width: 300px;
  }
}

button:hover {
  transition: all 0.1s ease-in-out;
  transform: scale(1.1);
  cursor: pointer;
}

button:not(:hover) {
  transform: scale(1.2);
}

#sender-email-success {
  text-align: center;
  font-family: 'Eastman Trial', sans-serif;
  font-size: 16px;
}