.companies-container {
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
  background-color: var(--background-gray-color);
  padding: 6em 0;

}

.companies-support {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  padding-bottom: 40px;

}

.companies-header {
  display: flex;
}

.companies-title {
  width: 250px;
  /* padding: 10px; */
  text-align: center;
  /* text-transform: uppercase; */
  font-size: 23px;
  font-weight: 700;
  /* letter-spacing: 8px; */
  font-family: 'Eastman Trial', sans-serif;
  color: white;
}

.companies-logos {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 70px;
}

.companies-logos-up {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 80px;
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.companies-logos-up.show,
.companies-logos-down.show,
.companies-footer-text.show {
  opacity: 1;
  transform: translateY(0);
}

.companies-logos-down img {
  flex: 1;
}

#bbg-logo {
  height: 170px;
}

#aceleres-logo {
  width: 200px;
}

#savee-logo {
  height: 150px;
}

#security-logo {
  height: 90px;
  width: 300px
}

#recycle-logo {
  width: 210px;
}

#brasnort-logo {
  width: 150px;
  margin-top: 40px;
}

#sblabs-logo {
  width: 190px;
}

#hydroheat-logo {
  width: 150px;
}

#bank-logo {
  width: 210px;
}

#ecoair-logo {
  width: 200px;
}

#newEnergy-logo {
  width: 230px;
}

#sbbusiness-logo {
  width: 280px
}

#carbonTrade-logo {
  width: 290px;
}

.companies-footer {
  text-align: center;
  width: 100%;
}

.companies-inner-container {
  display: flex;
  gap: 50px;
  width: 100%;
  justify-content: space-evenly;

  align-items: flex-end
}

.companies-footer-text {
  font-family: 'Eastman Trial', sans-serif;
  color: #fff;
  font-size: 30px;
  text-align: center;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease-out, transform 0.5s ease-out;
}

@media (max-width: 1440px) {
  .companies-inner-container {
    gap: 36px;
    /* flex-wrap: wrap; */
  }
}

@media (max-width: 1024px) {
  .companies-title {
    width: 200px;
    font-size: 20px;
  }

  .companies-inner-container {
    /* gap: 36px; */
    flex-wrap: wrap;
  }

  .companies-logos-up {
    align-items: center;
    flex-direction: column;
  }

  #iconArrow {
    transform: rotate(90deg);
  }

  .companies-logos-up {
    gap: 20px;
  }

  #brasnort-logo {
    margin-top: 0;
  }

  #hydroheat-logo {
    margin-bottom: 0;
  }

  #aceleres-logo {
    width: 150px;
  }

  #savee-logo {
    height: 120px;
  }

  #bbg-logo {
    height: 120px;
  }

  #sblabs-logo {
    height: 150px;
  }

  .companies-logos-down {
    gap: 20px;
  }

  .companies-footer-text {
    font-size: 19px;
  }


}


@media (max-width: 768px) {
  .companies-logos-up img {
    height: 150px;
  }

  .companies-footer-text {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .group-header-support {
    flex-direction: column;
  }

  .companies-support {
    width: 80%;
    align-items: center;
  }

  .companies-logos-up,
  .companies-logos-down {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
}