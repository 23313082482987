.partners-container {
  display: flex;
  flex-direction: column;
  height: auto;
  background-color: var(--background-gray-color);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

