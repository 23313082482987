@import url('https://fonts.cdnfonts.com/css/eastman-trial');

:root {
  --text-color: #aaa9a8;
  --background-gray-color: rgb(33,33,33)
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.header-container {
  display: flex;
  background-color: var(--background-gray-color);
  color: white;
  justify-content: space-around;
  align-items: center;
  padding: 1.5em 0;
}

.header-principal {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 60%;
  justify-content: space-between;
}

#header-fill {
  width: 20px;
  flex: 1;
}

#header-fill-content {
  height: 100%;
  width: 1px;
  background-color: #fff;
  height: 30px;
}


.header-list {
  text-transform: uppercase;
  list-style: none;
  display: flex;
  gap: 20px;
  color: var(--text-color);
  font-size: 11px;
  font-family: 'Eastman Trial', sans-serif;
  margin: 0;
  padding: 0;
}

.header-list li,
#linkedin-header img,
#header-flags img {
  cursor: pointer;
}

#linkedin-header img {
  height: 20px;
  width: 20px;
}

#header-flags {
  display: flex;
  gap: 2px;
}

#header-flags img {
  height: 20px;
  width: 25px;
}

@media (max-width: 950px) {
  .header-principal {
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
  }

  .header-list {
    gap: 10px;
    font-size: 11px;
  }
}

@media (max-width: 800px) {
  .header-list {
    font-size: 11px;
  }
}

@media (max-width: 1024px) {
  .about-right {
    display: none;
  }
}

@media (max-width: 768px) {
  #main-logo img {
    width: 150px;
  }
}

@media (max-width: 767px) {
  .header-list {
    display: none;
  }

  .about-information {
    width: 80%;
  }
}