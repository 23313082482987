@import url('https://fonts.googleapis.com/css?family=Proxima+Nova');

.group-container {
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
  background: url('../images/fachada.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  padding-top: 200px;

}

.group-support {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.group-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
}

.group-header-support {
  display: flex;
  gap: 80px;
}

.group-card {
  display: flex;
  align-items: center;
}

.group-card-title {
  color: white;
  font-family: 'Eastman Trial', sans-serif;
  font-size: 35px;
  text-align: right;
  line-height: 1;
}

.group-card-content {
  display: flex;
  font-family: 'Eastman Trial', sans-serif;
  color: white;
  font-weight: 500;
  font-size: 40px;
  align-items: center;

}


.group-card-number {
  color: #d2cbb8;
  font-size: 200px;
  font-family: 'proxima-nova', sans-serif;
  font-weight: 700;
  position: relative;
}

.flag-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.flag-group-image {
  width: 110px;
  height: 60px;
}

.group-flags {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 15px;
}

#group-flags-image {
  width: 200px;
}

#flag-support-text {
  font-size: 16px;
  text-align: center;
  color: white;
  font-family: 'Eastman Trial', sans-serif;
}

#divider-count {
  position: absolute;
  height: 1px;
  width: 35%;
  background-color: #d2cbb8;
  left: 35%;
  bottom: 20%;
  z-index: 100;

}

@media (min-width: 1024px) and (max-width: 1367px) {
  .group-card-number {
    font-size: 120px;

  }
}

@media (min-width: 1440px) and (max-width: 1700px) {
  .group-card-number {
    font-size: 140px;
  }
}

.group-teams-images {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.images-group {
  flex-basis: 24.7%;
}

@media (max-width: 1024px) {
  .group-header-support {
    gap: 40px;
    margin-bottom: 10px;
  }

  .group-card {
    gap: 2px;
  }

  .group-card-title {
    font-size: 20px;
  }

  .group-card-content {
    font-size: 30px;
  }

  .group-card-number {
    font-size: 60px;
    margin: 10px;
  }

  .images-group {
    width: 23%;
  }

  .flag-group-image {
    width: 90px;
    height: 40px;
  }

  .group-flags {
    margin-left: -50px;
  }
}

@media (max-width: 767px) {
  .group-flags {
    margin-left: 0px;
  }

  .flag-group-image {
    width: 60px;
    height: 30px;
  }
}