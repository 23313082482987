.map-container {
  position: relative;
}

iframe {
  width: 99vw;
  position: relative;
}

.map-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: none;
}
