.contact-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-gray-color);
  flex-direction: column;
  padding: 60px 0px;
}

.contact-support {
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: center;
}

#contact-title {
  display: block;
  width: 250px;
  border-top: 1px solid #d2cbb8;
  border-bottom: 1px solid #d2cbb8;
  border-left: 7px solid #d2cbb8;
  border-right: 7px solid #d2cbb8;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
  font-size: 25px;
  letter-spacing: 8px;
  font-family: 'Eastman Trial', sans-serif;
  color: #d2cbb8;
}

.contact-content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-left: 50px;
}

.contact-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  gap: 15px;
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.contact-card-image {
  height: 40px;
}

.contact-card-title {
  font-family: 'Eastman Trial', sans-serif;
  color: #c4beac;
  font-size: 25px;
  text-transform: uppercase;
  text-align: center;
}

.contact-card-text {
  font-size: 15px;
  text-align: center;
  line-height: 1.5;
  color: rgb(140, 142, 145);
  font-family: 'Eastman Trial', sans-serif;
}

.contact-card-fill {
  flex: 1;
  width: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-fill-content {
  width: 100%;
  height: 80%;
  background-color: rgb(144, 144, 144);
}

#contact-card-text-number {
  font-family: 'proxima-nova', sans-serif;
}

@media (max-width: 1024px) {
  #contact-title {
    width: 200px;
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .contact-support {
    width: 80%;
  }
  .contact-content {
    flex-direction: column;
    align-items: center;
    margin-left: 0;
  }
}